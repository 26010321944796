import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import {
  ActionFunctionArgs,
  redirect,
  unstable_createMemoryUploadHandler,
  unstable_parseMultipartFormData,
} from "@remix-run/node";
import { useNavigation, useSubmit } from "@remix-run/react";
import { FormEvent, FormEventHandler, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import currencies from "../assets/currencies.json";
import { createOnlineBill } from "../billService.server";
import CorrectExtractedInput from "../components/CorrectExtractedInput";
import { captureException } from "../errors";
import { parseScannedItemsToState, parseToSubmittingValues } from "../functions";
import { ScannedBill, StateLineItem } from "../types";

function createFormDataForParse(input: File) {
  const formData = new FormData();
  formData.append("image", input);

  return formData;
}

function createFormDataForSubmit(event: FormEvent<HTMLFormElement>, inputState: StateLineItem[]) {
  const $form = event.currentTarget;
  const formData = new FormData($form);

  const lineItems = parseToSubmittingValues(inputState.filter(element => !element.is_deleted));
  formData.append("line_items", JSON.stringify(lineItems));

  return formData;
}

const ALLOWED_CURRENCIES = currencies.map(currency => currency.cc);
const submitSchema = z.object({
  name: z.string(),
  payment_method: z.string(),
  currency: z.custom<string>(value => typeof value === "string" && ALLOWED_CURRENCIES.includes(value)),
  image: z.instanceof(File).and(
    z.object({
      size: z.number().max(7_500_000),
      type: z.literal("image/jpeg").or(z.literal("image/png")),
    }),
  ),
  line_items: z.string(),
  tip_amount: z.string().optional(),
});

const lineItemsSchema = z.array(
  z.object({
    description: z.string(),
    amount: z.number(),
    unit_price: z.number(),
    total_price: z.number(),
    is_tip: z.boolean().default(false),
  }),
);

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await unstable_parseMultipartFormData(
    request,
    unstable_createMemoryUploadHandler({
      maxPartSize: 10_000_000,
    }),
  );
  const parsed = submitSchema.safeParse(Object.fromEntries(formData));

  if (!parsed.success) {
    throw new Response("Bad Request", { status: 400 });
  }

  const lineItemsJson = JSON.parse(parsed.data.line_items);
  const parsedItems = lineItemsSchema.safeParse(lineItemsJson);

  if (!parsedItems.success) {
    throw new Response("Bad Request", { status: 400 });
  }

  if (parsed.data.tip_amount) {
    const tipAmount = parseInt(parsed.data.tip_amount, 10);

    if (!Number.isNaN(tipAmount) && Number.isFinite(tipAmount)) {
      parsedItems.data.push({
        amount: 1,
        description: "Tip",
        unit_price: tipAmount,
        total_price: tipAmount,
        is_tip: true,
      });
    }
  }

  const shareCode = await createOnlineBill(
    parsed.data.name,
    parsed.data.currency,
    parsed.data.payment_method,
    formData.get("image") as File,
    parsedItems.data,
  );

  return redirect(`/submitted?share_code=${shareCode}&name=${encodeURIComponent(parsed.data.name)}`);
};

export default function Index() {
  const submit = useSubmit();
  const { state } = useNavigation();

  const [selectedFile, setSelectedFile] = useState<File>();
  const [isUploadingFile, setUploadingFile] = useState(false);
  const [rawErrorMessage, setRawErrorMessage] = useState<string | undefined>();
  const [buttonTextIdx, setButtonTextIdx] = useState(0);
  const [initialScannedBill, setInitialScannedBill] = useState<ScannedBill>();
  const [inputState, setInputState] = useState<StateLineItem[]>([]);
  const isFileTooLarge = useMemo(() => (selectedFile ? selectedFile.size > 1024 * 1024 * 10 : false), [selectedFile]);

  useEffect(() => {
    if (!isUploadingFile) {
      setButtonTextIdx(0);

      return () => undefined;
    }

    const intervalId = setInterval(() => {
      setButtonTextIdx(current => {
        const nextValue = current + 1;
        return nextValue >= BUTTON_TEASER_TEXTS.length ? current : nextValue;
      });
    }, 1500);

    setButtonTextIdx(1);

    return () => clearInterval(intervalId);
  }, [isUploadingFile]);

  const handleUploadFile = async (file: File) => {
    setSelectedFile(file);

    try {
      setUploadingFile(true);

      const response = await fetch("/parse-image", {
        method: "POST",
        body: createFormDataForParse(file),
      });

      if (response.status !== 200) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }

      const content = (await response.json()) as ScannedBill;
      setInitialScannedBill(content);
      setInputState(parseScannedItemsToState(content.line_items));

      window.scrollTo(0, 0);
    } catch (error) {
      captureException(error);
      setRawErrorMessage((error as Error).message);
    } finally {
      setUploadingFile(false);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();

    if (selectedFile === undefined) {
      return;
    }

    try {
      const formData = createFormDataForSubmit(event, inputState);
      submit(formData, { method: "POST", encType: "multipart/form-data" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack direction="column" marginX="auto" maxWidth="768px" paddingY={2} paddingX={2}>
      {initialScannedBill === undefined && !isFileTooLarge && (
        <Box component="header" marginY={3}>
          <Avatar src="/billy-logo.webp" variant="plain" sx={{ marginX: "auto" }} />
          <Typography level="h1">Want to split a bill?</Typography>
          <Typography>Use Billy to scan the bill to share it and keep track of who paid back what 💰💰</Typography>
        </Box>
      )}
      <main>
        <form onSubmit={handleSubmit}>
          <Stack rowGap={2}>
            {rawErrorMessage !== undefined && (
              <Alert color="danger">{prettyErrorMessageFromRaw(rawErrorMessage)}</Alert>
            )}
            <FormControl
              error={isFileTooLarge}
              sx={{ display: initialScannedBill !== undefined ? "none" : undefined }}
              required
            >
              <FormLabel>Select a bill to get started</FormLabel>
              {/* @ts-expect-error not sure why thought */}
              <Input
                name="image"
                onChange={event => {
                  const file = event.currentTarget?.files?.[0];
                  if (!file) return;

                  handleUploadFile(file);
                }}
                type="file"
                accept="image/*"
                slotProps={{
                  root: {
                    sx: { alignItems: "center" },
                  },
                }}
              />
              {!isFileTooLarge ? (
                <FormHelperText>Take a picture or select it from your library</FormHelperText>
              ) : (
                <FormHelperText>Please select a file less than 10mb</FormHelperText>
              )}
            </FormControl>

            {initialScannedBill !== undefined && (
              <CorrectExtractedInput
                onChangeTableState={setInputState}
                onDifferentImage={() => {
                  setInitialScannedBill(undefined);
                  setInputState([]);
                  setSelectedFile(undefined);
                }}
                initialValues={{
                  name: initialScannedBill.name,
                  date: initialScannedBill.date,
                  currency: initialScannedBill.currency,
                }}
                isSubmitting={state === "submitting"}
                tableState={inputState}
              />
            )}

            {isUploadingFile && (
              <Alert color="neutral" startDecorator="🔍" variant="outlined">
                {BUTTON_TEASER_TEXTS[buttonTextIdx]}
              </Alert>
            )}
          </Stack>
        </form>

        {selectedFile === undefined && (
          <Card size="lg" variant="plain" orientation="vertical" sx={{ marginY: 2 }}>
            <Typography level="h4" startDecorator="💡">
              How it works
            </Typography>
            <Divider />
            <CardContent>
              <Typography fontSize="sm">1. Upload your image</Typography>
              <Typography fontSize="sm">2. Share the link with friends</Typography>
              <Typography fontSize="sm">3. Sit back while you get paid what you owe ✨</Typography>
              <Stack sx={{ display: "flex", flexDirection: "row", columnGap: 2, marginTop: 1 }}>
                {["step-1.png", "step-3.png"].map(imageSrc => (
                  <picture key={imageSrc}>
                    <source srcSet={`/teasers/dark-theme/${imageSrc}`} media="(prefers-color-scheme: dark)" />
                    <img
                      alt="explainer 1"
                      src={`/teasers/light-theme/${imageSrc}`}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </picture>
                ))}
              </Stack>
            </CardContent>
          </Card>
        )}
      </main>
    </Stack>
  );
}

function prettyErrorMessageFromRaw(message: string) {
  if (message.includes("Upstream failed. Received error: ")) {
    return "We're having trouble processing your image. Please use another one.";
  }

  if (message.includes("Upsteam failed. No `Operation-Location` received")) {
    return "We're having trouble processing your image. Please try again.";
  }

  if (message.includes("Upstream failed. Too many requests")) {
    return "We're having trouble processing your image. It's probably too big. Can you zoom in on the bill items?";
  }

  if (message.includes("Upstream failed. No analyzed document returned")) {
    return "We're having trouble processing your image. Are you sure it's a bill and properly readable?";
  }

  return "We're having trouble processing your image. Please try again.";
}

const BUTTON_TEASER_TEXTS = [
  "Continue 🔍",
  "Uploading image",
  "Identifying text",
  "Extracting venue name",
  "Extracting line items",
];
