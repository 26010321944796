import { Option, Select, SelectProps } from "@mui/joy";

import currencies from "../assets/currencies.json";

export function CurrencyInput(props: SelectProps<string, false>) {
  return (
    <Select {...props}>
      {currencies.map(element => (
        <Option key={element.cc} value={element.cc}>
          {element.cc} - {element.name}
        </Option>
      ))}
    </Select>
  );
}
