import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  SupportedValue,
  Table,
  ToggleButtonGroup,
  Typography,
} from "@mui/joy";
import React, { useEffect, useMemo, useRef, useState } from "react";

import currencies from "../assets/currencies.json";
import { extractFirstUrl, formatCurrencyWithoutSymbol, tryParseFloat } from "../functions";
import { ScannedBill, StateLineItem } from "../types";
import { CurrencyInput } from "./CurrencyInput";

interface CorrectExtractedInputProps {
  onChangeTableState: React.Dispatch<React.SetStateAction<StateLineItem[]>>;
  onDifferentImage: () => void;
  initialValues: Pick<ScannedBill, "name" | "date" | "currency">;
  isSubmitting: boolean;
  tableState: StateLineItem[];
}

const PLACEHOLDER_ITEM: StateLineItem = {
  is_deleted: false,
  description: "",
  amount: "1",
  unit_price: "0",
  total_price: "0",
};

export default function CorrectExtractedInput({
  onChangeTableState,
  onDifferentImage,
  initialValues,
  isSubmitting,
  tableState,
}: CorrectExtractedInputProps) {
  const headerTextRef = useRef<HTMLSpanElement>(null);
  const [tipPercentage, setTipPercentage] = useState<number | undefined>();
  const [tipAmount, setTipAmount] = useState("");
  const [currency, setCurrency] = useState(initialValues.currency ?? "EUR");
  const currencySymbol = useMemo(
    () => currencies.find(element => element.cc === currency)?.symbol as string,
    [currency],
  );

  const totalSum = tableState
    .filter(element => !element.is_deleted)
    .reduce((accumulator, current) => accumulator + (tryParseFloat(current.total_price) as number), 0);

  useEffect(() => {
    if (tipPercentage === undefined) return;
    setTipAmount(((tipPercentage / 100) * totalSum).toFixed(2));
  }, [totalSum, tipPercentage]);

  useEffect(() => {
    requestAnimationFrame(() => {
      headerTextRef.current?.scrollIntoView();
    });

    // ..
  }, [initialValues]);

  const handleDeleteItem = (index: number) => {
    onChangeTableState(currentState =>
      currentState.with(index, {
        ...currentState[index],
        is_deleted: !currentState[index].is_deleted,
      }),
    );
  };

  const handleAppendItem = () => {
    onChangeTableState(current => [...current, PLACEHOLDER_ITEM]);

    requestAnimationFrame(() => {
      const inputElement = document.querySelector("table > tbody > tr:nth-last-child(2) > td:first-child");
      (inputElement as HTMLInputElement)?.focus();
    });
  };

  const handleBlurAfterEnter = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key !== "Enter") return;
    (event.target as HTMLInputElement)?.blur();
  };

  const handleSyncElement = (index: number, field: "amount" | "unit_price" | "total_price", nextValue: string) => {
    const item = tableState[index];

    const amount = tryParseFloat(field === "amount" ? nextValue : item.amount);
    let unit_price = tryParseFloat(field === "unit_price" ? nextValue : item.unit_price);
    let total_price = tryParseFloat(field === "total_price" ? nextValue : item.total_price);

    const hasAmount = typeof amount === "number";
    const hasUnitPrice = typeof unit_price === "number";
    const hasTotalPrice = typeof total_price === "number";

    if (field === "amount" && hasAmount && hasUnitPrice) {
      total_price = amount * (unit_price as number);
    } else if (field === "unit_price" && hasUnitPrice && hasAmount) {
      total_price = amount * (unit_price as number);
    } else if (field === "total_price" && hasTotalPrice && hasAmount) {
      unit_price = (total_price as number) / amount;
    }

    onChangeTableState(current =>
      current.with(index, {
        ...item,
        amount: String(amount),
        unit_price: formatCurrencyWithoutSymbol(unit_price),
        total_price: formatCurrencyWithoutSymbol(total_price),
      }),
    );
  };

  return (
    <Stack direction="column" rowGap={2}>
      <Box>
        <Typography ref={headerTextRef} level="h4">
          👌 Here's the items we found
        </Typography>
        <Typography>You can correct any mistakes by tapping the value. Or add and remove items.</Typography>
      </Box>
      <FormControl>
        <FormLabel>Detected currency</FormLabel>
        <CurrencyInput
          name="currency"
          onChange={(_event, value) => {
            setCurrency(value as string);
          }}
          value={currency}
        />
      </FormControl>
      <Table>
        <thead>
          <tr>
            <th scope="col" style={{ width: "40%" }}>
              Item
            </th>
            <th scope="col">Qty</th>
            <th scope="col">Unit</th>
            <th scope="col">Total</th>
            <th aria-label="actions" scope="col" style={{ maxWidth: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {tableState.map((element, index) => (
            <tr
              // eslint-disable-next-line react/no-array-index-key
              key={element.description + index}
              style={{
                textDecorationLine: element.is_deleted ? "line-through" : undefined,
              }}
            >
              <td
                aria-label="edit description"
                contentEditable={!element.is_deleted}
                suppressContentEditableWarning
                onBlur={event => {
                  onChangeTableState(currentState => {
                    const nextState = [...currentState];
                    nextState[index].description = event.target.innerText;

                    return nextState;
                  });
                }}
                onKeyUp={handleBlurAfterEnter}
              >
                {element.description}
              </td>
              <td
                aria-label="edit amount"
                contentEditable={!element.is_deleted}
                suppressContentEditableWarning
                onBlur={event => handleSyncElement(index, "amount", event.target.innerText)}
                onKeyUp={handleBlurAfterEnter}
                inputMode="numeric"
              >
                {element.amount}
              </td>
              <td
                aria-label="edit unit price"
                contentEditable={!element.is_deleted}
                suppressContentEditableWarning
                onBlur={event => handleSyncElement(index, "unit_price", event.target.innerText)}
                onKeyUp={handleBlurAfterEnter}
                inputMode="decimal"
                role="textbox"
                tabIndex={0}
              >
                {element.unit_price}
              </td>
              <td
                aria-label="edit total price"
                contentEditable={!element.is_deleted}
                suppressContentEditableWarning
                onBlur={event => handleSyncElement(index, "total_price", event.target.innerText)}
                onKeyUp={handleBlurAfterEnter}
                inputMode="decimal"
                role="textbox"
                tabIndex={0}
              >
                {element.total_price}
              </td>
              <td onClick={() => handleDeleteItem(index)} style={{ cursor: "pointer" }}>
                {element.is_deleted ? "🔄" : "🗑️"}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={5}>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={() => handleAppendItem()} variant="plain">
                  Add item 🆕
                </Button>
              </Box>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>Total</td>
            <td colSpan={2}>
              <span>{currencySymbol} </span>
              {formatCurrencyWithoutSymbol(totalSum)}
            </td>
          </tr>
        </tfoot>
      </Table>

      <FormControl>
        <FormLabel>Did you also add a tip?</FormLabel>
        <Stack alignItems="center" direction="row" columnGap={1}>
          <ToggleButtonGroup
            onChange={(event, value) => {
              // eslint-disable-next-line no-underscore-dangle
              const tipPercentage_ = parseInt(value as string, 10);
              setTipPercentage(tipPercentage_);
            }}
            value={tipPercentage as unknown as SupportedValue}
            variant="outlined"
          >
            <Button value="5">5%</Button>
            <Button value="10">10%</Button>
            <Button value="15">15%</Button>
            <Button value="20">20%</Button>
          </ToggleButtonGroup>
          <Typography>or</Typography>
          <Input
            name="tip_amount"
            onChange={event => {
              setTipPercentage(undefined);
              setTipAmount(event.target.value);
            }}
            inputMode="decimal"
            placeholder="Amount"
            startDecorator={currencySymbol}
            style={{ maxWidth: 145 }}
            value={tipAmount}
          />
        </Stack>
      </FormControl>

      <FormControl required>
        <FormLabel>What is this bill for?</FormLabel>
        <Input name="name" slotProps={{ input: { min: 3 } }} defaultValue={initialValues.name ?? undefined} />
      </FormControl>

      <FormControl required>
        <FormLabel>How do you want to get paid back?</FormLabel>
        <Input
          onPaste={event => {
            const value = event.clipboardData.getData("text");
            const urlFoundInText = extractFirstUrl(value);
            const $inputElement = event.currentTarget.firstChild;

            if ($inputElement === null) {
              return;
            }

            requestAnimationFrame(() => {
              const nextValue = urlFoundInText !== null && urlFoundInText.length > 0 ? urlFoundInText : value;
              ($inputElement as HTMLInputElement).value = nextValue;
            });
          }}
          name="payment_method"
          slotProps={{ input: { min: 3 } }}
        />
        <FormHelperText>Tip: Link to a payment request from your bank/ payment provider</FormHelperText>
      </FormControl>

      <Stack direction="row" justifyContent="space-between">
        <Button onClick={() => onDifferentImage()} variant="outlined">
          ↩ Use different image
        </Button>
        <Button loading={isSubmitting} type="submit">
          Save 💾
        </Button>
      </Stack>
    </Stack>
  );
}
